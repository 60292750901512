<template>
  <div>
    <loader v-show="loading"></loader>
    <div v-show="!loading">
      <iframe :src="url" frameborder="0" v-if="url.length > 0" @load="onMyFrameLoad()"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "tinkoff",
  components: {
    loader: () => import('@/components/other/loader'),
  },
  props: {
    dealObj: {
      type: Object,
      default() {
        return {
          DlCode: '00',
          CurPay: 0,
        }
      },
    },
  },
  data() {
    return {
      loading: true,
      url: '',
    }
  },
  watch: {
    'dealObj.CurPay': {
      handler() {
        this.createUrl()
      }
    }
  },
  methods: {
    onMyFrameLoad() {
      this.loading = false;
    },
    createUrl() {
      this.$myApi.singleRequest({
        action: 'getTinkoff',
        type: 'payment',
        amount: this.dealObj.CurPay,
        demand: parseInt(this.dealObj.DlCode, 10),
      }).then((res) => {
        if (res.isSuccess) {
          this.url = res.paymentUrl;
        } else {
          alert('Оплата не возможна, платежный сервис не доступен');
        }
      }).catch((res) => {
        alert(JSON.stringify(res));
      });
    },
    loadingDAtaLostil() {
      if (this.dealObj.DlCode != undefined) {
        this.createUrl();
      } else {
        setTimeout(() => {
          this.loadingDAtaLostil();
        }, 500)
      }
    }
  },
  mounted() {

    if (this.dealObj.DlCode != undefined) {
      this.createUrl();
    } else {
      this.$store.dispatch('updateContentData').then(() => {
        this.loadingDAtaLostil();
      })
    }
  }
}
</script>

<style scoped>
  iframe{width: 100%;height: 900px}
</style>
